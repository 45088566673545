import { useDeviceList } from '@/core/useDeviceList';
import { useUserMedia } from "@/core/useUserMedia";
import { useMonitorLogging } from '@/core/useMonitor/logging';
import { ref } from 'vue';

/**
 * Options defined as vanilla object until we can support TypeScript interfaces.
 */
export const UseMonitorCameraOptions = {
  // Callback invoked when device is connected
  onConnect: null,
  // Callback invoked when device is disconnected
  onDisconnect: null,
  // Callback invoked when no devices are present
  onError: null,
  // Whether the stream is automatically started
  autoStart: true,
}

/**
 * Reactively stream a requested media device and handle available input/output devices and permissions.
 *
 * @param options
 */
export function useMonitorCamera(options = {}) {
  options = { ...UseMonitorCameraOptions, ...options };

  const cameraId = ref();
  const cameraLabel = ref();

  const {
    cameraEvents,
    logCameraLost,
    logCameraReturn,
  } = useMonitorLogging();

  const {
    videoInputs: cameras,
    isSupported: isCameraSupported,
    permissionGranted: isCameraPermissionGranted,
  } = useDeviceList({
    requestPermissions: true,
    constraints: { audio: false, video: true },
    onUpdated: function() {
      if (!cameras.value.find(camera => camera.deviceId === cameraId.value)) {
        cameraId.value = cameras.value[0]?.deviceId;
        cameraLabel.value = cameras.value[0]?.label;
      }
    }
  });

  const {
    isStreaming: isCameraStreaming,
    stream: cameraStream,
    start: startCamera,
    stop: stopCamera,
    restart: restartCamera,
    constraints: cameraConstraints,
    enabled: cameraEnabled,
    autoSwitch: cameraAutoSwitch,
  } = useUserMedia({
    constraints: { video: { deviceId: cameraId, facingMode: 'user' }, audio: false },
    onConnect: function() {
      logCameraReturn().then(() => {});
      options.onConnect?.();
    },
    onDisconnect: function() {
      logCameraLost().then(() => {});
      options.onDisconnect?.();
    },
    onError: function(error) {
      options.onError?.(error);
    },
  });

  if (options.autoStart) {
    startCamera().then(() => {});
  }

  return {
    isCameraSupported,
    isCameraStreaming,
    isCameraPermissionGranted,
    cameras,
    cameraId,
    cameraLabel,
    cameraEnabled,
    cameraStream,
    cameraEvents,
    cameraConstraints,
    cameraAutoSwitch,
    startCamera,
    stopCamera,
    restartCamera,
  }
}
