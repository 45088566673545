import { ref, shallowRef, computed, watch } from 'vue';
import { useSupported, usePermission, useEventListener, tryOnScopeDispose } from '@vueuse/core';

/**
 * Options defined as vanilla object until we can support TypeScript interfaces.
 */
export const UseUserMediaOptions = {
  // Callback invoked when device is connected
  onConnect: null,
  // Callback invoked when device is disconnected
  onDisconnect: null,
  // Callback invoked when no devices are present
  onError: null,
  // Whether the stream is enabled
  enabled: false,
  // Whether the stream is recreated when deviceIds or constraints change
  autoSwitch: false,
  // Constraints applied to the requested media stream
  constraints: {},
}

/**
 * Reactively stream a requested media device.
 *
 * @param options
 */
export function useUserMedia(options = {}) {
  options = { ...UseUserMediaOptions, ...options };

  const navigator = window.navigator;
  const enabled = ref(options.enabled);
  const autoSwitch = ref(options.autoSwitch);
  const constraints = ref(options.constraints);
  const stream = shallowRef();
  const isSupported = useSupported(() => navigator?.mediaDevices?.getUserMedia);
  const isStreaming = computed(() => { return stream.value !== undefined });

  function getDeviceOptions(type) {
    if (constraints.value) {
      return constraints.value[type] || false
    }
    return false;
  }

  async function _start() {
    if (!isSupported.value || stream.value) {
      return;
    }
    try {
      stream.value = await navigator.mediaDevices.getUserMedia({
        video: getDeviceOptions('video'),
        audio: getDeviceOptions('audio'),
      });
      stream.value.getVideoTracks()[0].onended = () => {
        _stop();
        options.onDisconnect?.();
      };
    } catch (error) {
      // Firefox no devices found
      if (error.message.includes('The object can not be found here')) {
        // Special handling??
      }
      options.onError?.(error);
    }
    return stream.value;
  }

  function _stop() {
    stream.value?.getTracks().forEach(track => track.stop());
    stream.value = undefined;
  }

  function stop() {
    _stop();
    enabled.value = false;
  }

  async function start() {
    await _start();
    if (stream.value) {
      enabled.value = true;
      options.onConnect?.();
    }
    return stream.value;
  }

  async function restart() {
    _stop();
    return await start();
  }

  watch(enabled, (current) => {
      if (current) {
        _start().then(() => {});
      } else {
        _stop();
      }
  }, { immediate: true });

  watch(constraints, () => {
    if (autoSwitch.value && stream.value) {
      restart().then(() => {});
    }
  }, { immediate: true });

  tryOnScopeDispose(() => {
    stop();
  });

  return {
    isSupported,
    isStreaming,
    stream,
    start,
    stop,
    restart,
    constraints,
    enabled,
    autoSwitch,
  }
}
