<template>
  <ion-button v-tooltip="{ location:tooltipLocation}"
              :data-title="fullScreenLabel"
              fill="clear"
              class="icon-button full-screen-button"
              v-if="isFullscreenSupported"
              :aria-label="fullScreenLabel">
    <ion-icon class="icon full-screen-icon" :class="[is_full_screen ? 'exit-full-screen': 'full-screen']" aria-hidden="true"/>
  </ion-button>

</template>

<script>
import {IonButton, IonIcon, IonProgressBar} from '@ionic/vue';
import {computed, defineComponent, toRef} from 'vue';
import store from "@/store";

export default defineComponent({
  name: 'FullScreenButton',
  components: {IonButton, IonIcon, IonProgressBar},
  props: {
    isFullscreenSupported: Boolean,
    isFullscreen: Boolean,
    tooltipLocation: {
      type: String, default: () => {
        return 'top'
      }
    }
  },
  setup(props, ctx) {
    const is_full_screen = toRef(props, 'isFullscreen')
    const language_set= store.getters.languageSet;

    const fullScreenLabel = computed(() => {
      if (is_full_screen.value) {
        return language_set['exit_full_screen']
      } else {
        return language_set['enter_full_screen']
      }
    })


    return {is_full_screen, fullScreenLabel}

  }
});
</script>

<style lang="scss" scoped>
@import '../../theme/classmarker_theme';

.icon-button.full-screen-button {
  width: 20px;
  height: 20px;



  @include responsive(size-small) {
    display: none;
  }
}

.full-screen-icon{
  width: 20px;
  height: 20px;
  background-size:20px;
}

</style>