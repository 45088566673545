import { ref } from 'vue';
import store from '@/store';

export const MonitorLoggingEvent = {
  CURSOR_LOST: 'cur_l',
  CURSOR_RETURN: 'cur_r',
  FOCUS_LOST: 'foc_l',
  FOCUS_RETURN: 'foc_r',
  TAB_LOST: 'tab_l',
  TAB_RETURN: 'tab_r',
  FULL_SCREEN_LOST: 'fsc_l',
  FULL_SCREEN_RETURN: 'fsc_r',
  CAMERA_LOST: 'cam_l',
  CAMERA_RETURN: 'cam_r',
};

const browserEventTypes = [
  MonitorLoggingEvent.CURSOR_LOST,
  MonitorLoggingEvent.CURSOR_RETURN,
  MonitorLoggingEvent.FOCUS_LOST,
  MonitorLoggingEvent.FOCUS_RETURN,
  MonitorLoggingEvent.TAB_LOST,
  MonitorLoggingEvent.TAB_RETURN,
  MonitorLoggingEvent.FULL_SCREEN_LOST,
  MonitorLoggingEvent.FULL_SCREEN_RETURN
];

const cameraEventTypes = [
  MonitorLoggingEvent.CAMERA_LOST,
  MonitorLoggingEvent.CAMERA_RETURN
];

/**
 * Shared global state.
 */
const browserEvents    = ref([]);
const cameraEvents     = ref([]);

/**
 * Coordinates logging of browser and camera events.
 */
export function useMonitorLogging() {
  async function submitEvent(event) {
    try {
      await store.dispatch('Monitoring/submitEvent', event);
    } catch (error) {
    }
  }

  async function logEvent(type) {
    const event = {
      type: type,
      isLostEvent: function() {
        return this.type.endsWith('_l');
      },
      isReturnEvent: function() {
        return this.type.endsWith('_r');
      },
      isBrowserEvent: function() {
        return browserEventTypes.includes(this.type);
      },
      isCameraEvent: function() {
        return cameraEventTypes.includes(this.type);
      },
      isRelated: function(event) {
        const current = this.type.replace('_l', '').replace('_r', '');
        const other = event.type.replace('_l', '').replace('_r', '');
        return current === other;
      }
    };

    const eventStack = event.isBrowserEvent() ? browserEvents.value : cameraEvents.value;
    const lastEvent = eventStack.length > 0 ? eventStack.at(-1) : null;

    if (! lastEvent && event.isLostEvent()) {
      eventStack.push(event);
      await submitEvent(event);
    } else if (lastEvent && lastEvent.isLostEvent() && event.isReturnEvent() && event.isRelated(lastEvent)) {
      eventStack.push(event);
      await submitEvent(event);
    } else if (lastEvent && lastEvent.isReturnEvent() && event.isLostEvent()) {
      eventStack.push(event);
      await submitEvent(event);
    }
  }

  async function logCursorLost(type) {
    return await logEvent(MonitorLoggingEvent.CURSOR_LOST);
  }

  async function logCursorReturn(type) {
    return await logEvent(MonitorLoggingEvent.CURSOR_RETURN);
  }

  async function logFocusLost(type) {
    return await logEvent(MonitorLoggingEvent.FOCUS_LOST);
  }

  async function logFocusReturn(type) {
    return await logEvent(MonitorLoggingEvent.FOCUS_RETURN);
  }

  async function logTabLost(type) {
    return await logEvent(MonitorLoggingEvent.TAB_LOST);
  }

  async function logTabReturn(type) {
    return await logEvent(MonitorLoggingEvent.TAB_RETURN);
  }

  async function logFullScreenLost(type) {
    return await logEvent(MonitorLoggingEvent.FULL_SCREEN_LOST);
  }

  async function logFullScreenReturn(type) {
    return await logEvent(MonitorLoggingEvent.FULL_SCREEN_RETURN);
  }

  async function logCameraLost(type) {
    return await logEvent(MonitorLoggingEvent.CAMERA_LOST);
  }

  async function logCameraReturn(type) {
    return await logEvent(MonitorLoggingEvent.CAMERA_RETURN);
  }

  async function closeLostEventAfterHalting() {
    const lastBrowserEvent = browserEvents.value.length > 0 ? browserEvents.value.at(-1) : null;
    const lastCameraEvent = cameraEvents.value.length > 0 ? cameraEvents.value.at(-1) : null;
    if (lastBrowserEvent && lastBrowserEvent.isLostEvent()) {
      const returnBrowserEventType = lastBrowserEvent.type.replace('_l', '_r');
      await logEvent(returnBrowserEventType);
    }
    if (lastCameraEvent && lastCameraEvent.isLostEvent()) {
      const returnCameraEventType = lastCameraEvent.type.replace('_l', '_r');
      await logEvent(returnCameraEventType);
    }
    return Promise.resolve(null);
  }

  return {
    browserEvents,
    cameraEvents,
    logCursorLost,
    logCursorReturn,
    logFocusLost,
    logFocusReturn,
    logTabLost,
    logTabReturn,
    logFullScreenLost,
    logFullScreenReturn,
    logCameraLost,
    logCameraReturn,
    closeLostEventAfterHalting,
  }
}
