import store from '@/store';

/**
 * Options defined as vanilla object until we can support TypeScript interfaces.
 */
export const UseMonitorSnapshotOptions = {
  // Callback invoked when a photo is captured and saved
  onCapture: null,
  // A string indicating the image format
  mime: 'image/png',
  // A Number between 0 and 1 indicating the image quality to be used when capturing photos
  quality: 1,
  // The captured image output, either base64 or blob
  output: 'base64',
}

export function useMonitorSnapshot(video, canvas, options) {
  options = { ...UseMonitorSnapshotOptions, ...options };

  async function submitImage(type, data) {
    try {
      const event  = {
        type: type,
        data: data,
        base_64: options.output === 'base64'
      };
      return await store.dispatch('Monitoring/submitImage', event);
    } catch (error) {
      return false;
    }
  }

  async function submitSnapshot(snapshot, data) {
    try {
      const event  = {
        type: snapshot.type,
        data: data,
        base_64: options.output === 'base64',
        question_id: snapshot.question_id,
        page: snapshot.page,
      };
      return store.dispatch('Monitoring/submitImage', event).then((success) => {
        if (success) {
          store.dispatch('Monitoring/setSnapshotCapturedById', snapshot.id);
        }
        return success;
      });
    } catch (error) {
      return false;
    }
  }

  function snapshot() {
    canvas.value.width = video.value.videoWidth;
    canvas.value.height = video.value.videoHeight;

    const context = canvas.value.getContext('2d');
    context?.clearRect(0, 0, canvas.value.width, canvas.value.height);
    context?.drawImage(video.value, 0, 0, canvas.value.width, canvas.value.height);

    return canvas.value;
  }

  async function captureAsBlob() {
    return new Promise((resolve, reject) => {
      try {
        snapshot().toBlob(blob => resolve(blob), options.mime, options.quality);
      } catch (error) {
        reject(error);
      }
    });
  }

  async function captureAsBase64() {
    return new Promise((resolve, reject) => {
      try {
        resolve(snapshot().toDataURL(options.mime, options.quality));
      } catch (error) {
        reject(error);
      }
    });
  }

  async function capture() {
    const output = { blob: captureAsBlob, base64: captureAsBase64 };
    const type = options.output === 'blob' ? 'blob' : 'base64';
    const image = await output[type]();

    return new Promise((resolve, reject) => {
      try {
        options.onCapture?.(image);
        resolve(image);
      } catch (error) {
        resolve();
      }
    });
  }

  return {
    capture,
    submitImage,
    submitSnapshot,
  }
}
