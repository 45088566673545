<template>
  <div style="height:20px;">
    <svg class="stat-circle" width="20" viewBox="0 0 20 20">
      <circle class="bg" cx="10" cy="10" r="8"
              :style="{'stroke-dashoffset': remainingPercent , 'stroke': timer_colour}"/>
      <circle class="progress" cx="10" cy="10" r="8" ref="progress-circle"
              :style="'stroke-dashoffset:' + progressPercent "/>
    </svg>
  </div>
</template>
<script>
import {computed, defineComponent, toRef} from "vue";
import store from "@/store";

export default defineComponent({
  name: 'CircleProgress',
  components: {},
  props: {
    percentage: Number,
  },
  setup(props, ctx) {

    let theme = store.getters.theme;
    let timer_colour = theme.timer_bar_color;

    const percentage = toRef(props, 'percentage')

    const progressPercent = computed(() => {
      return -51 - (51 / 100 * percentage.value);
    });

    const remainingPercent = computed(() => {
      return -1 * (51 / 100 * percentage.value);
    });

    return {progressPercent, remainingPercent, timer_colour};
  },
});
</script>
<style>
.stat-circle {
  transform: rotate(-90deg);

  circle.bg {
    fill: none;
    //stroke: #FB3311;
    stroke-width: 4px;
    stroke-dasharray: 51 51;
  }

  circle.progress {
    fill: none;
    stroke: #E9ECEF;
    stroke-width: 4px;
    stroke-dasharray: 51 51;
  }

}

</style>