<template>
  <div class="camera-container">
    <div :class="['camera-photo', props.stream === undefined ? 'disconnected' : 'connected', captured ? 'captured' : '']">
      <div class="camera-background">
        <video
            ref="video"
            muted
            autoplay />
        <img ref="preview" />
        <canvas ref="canvas" style="display: none;" />
        <div v-if="props.capture" class="camera-controls">
          <ion-button
              v-if="!captured"
              shape="circle"
              color="light"
              :disabled="props.stream === undefined"
              @click="captureImage">
            <ion-icon
                class="icon"
                slot="icon-only"
                aria-hidden="true"
                size="medium"
                :src="require('@/assets/icons/camera-capture.svg')" />
          </ion-button>
          <ion-button
              v-if="captured"
              shape="circle"
              color="light"
              :disabled="props.stream === undefined"
              @click="onRetake">
            <ion-icon
                class="icon"
                slot="icon-only"
                aria-hidden="true"
                size="medium"
                :src="require('@/assets/icons/camera-retake.svg')" />
          </ion-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import { IonButton, IonIcon } from '@ionic/vue';
import { useStore } from 'vuex';
import { useMonitorSnapshot } from '@/core/useMonitor/snapshot';

const emit = defineEmits([
  'captured',
  'retake',
]);

const props = defineProps({
  // MediaStream instance containing video tracks
  stream: {
    type: Object,
    default: undefined
  },
  // Whether capturing a photo is required
  capture: {
    type: Boolean,
    default: false
  },
});

const store = useStore();
const video = ref(null);
const canvas = ref(null);
const preview = ref(null);
const captured = ref(false);

const {
  capture: captureImage,
  submitImage: submitImage
} = useMonitorSnapshot(video, canvas, {
  onCapture: function(image) {
    preview.value.src = image;
    captured.value = true;
    emit('captured', image);
  }
});

const onRetake = () => {
  captured.value = false;
  emit('retake');
}

watchEffect(() => {
  if (video.value) {
    video.value.srcObject = props.stream;
  }
});

</script>

<style lang="scss" scoped>
ion-button[shape=circle] {
  --border-radius: 50%;
  width: 64px;
  height: 64px;
}

.camera-container {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
}

.camera-controls {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;
  bottom: 0;
  z-index: 200;
}

.camera-photo {
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  max-width: 600px;
  min-height: 200px;
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 767px) {
  .camera-photo {
    min-height: 380px;
  }
}

.camera-photo video {
  width: 100%;
  height: auto;
  display: block;
}

.camera-photo img {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.camera-photo.captured img {
  display: block;
}

.camera-photo.connected {
}

.camera-photo.disconnected {
}

.camera-background {
  background-color: #212121;
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 100%;
}

.camera-photo.disconnected .camera-background:after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: transparent url('~@/assets/image/noise.png') repeat 0 0;
  animation: noise-animation .2s infinite;
  opacity: 1;
}

@keyframes noise-animation {
  0% { transform: translate(0,0) }
  10% { transform: translate(-5%,-5%) }
  20% { transform: translate(-10%,5%) }
  30% { transform: translate(5%,-10%) }
  40% { transform: translate(-5%,15%) }
  50% { transform: translate(-10%,5%) }
  60% { transform: translate(15%,0) }
  70% { transform: translate(0,10%) }
  80% { transform: translate(-15%,0) }
  90% { transform: translate(10%,5%) }
  100% { transform: translate(5%,0) }
}

</style>