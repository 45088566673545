<template>
  <div class="generic-instructions">
    <ion-grid class="ion-no-padding">
      <ion-row v-if="settings['display_pretest_instruction']">
        <ion-col class="pretest-instructions">
          <div class="title">
            <ion-label class="ion-text-wrap">{{ language['test_information_title'] }}</ion-label>
          </div>
          <ul>
            <li v-if="data.num_of_questions && data.num_of_questions > 0">
              {{ language['test_information_noq'] }}: <strong>{{ data.num_of_questions }}</strong>
            </li>

            <li v-if="settings['display_time_limit']">
              {{ language['test_information_time_limit'] }}: <strong>{{
                convertDurationString(settings['display_time_limit'])
              }}</strong>
            </li>

            <li v-if="isMonitoringCameraEnabled">
              {{ language['test_monitoring_camera_label'] }}: <strong>{{
                language['test_information_required']
              }}</strong>
            </li>

            <li v-if="isMonitoringBrowserEnabled">
              <ion-label class="ion-text-wrap">{{ language['test_monitoring_browser_instructions'] }}</ion-label>
            </li>

            <li v-if="isGroupTest">
              {{ language['test_information_attempts'] }}: <strong>{{ attemptsAllowed }}</strong>
            </li>

            <li v-if="settings['resume_later'] === false">
              <ion-label class="ion-text-wrap">{{ language['test_information_no_sfl'] }}</ion-label>
            </li>
            <li v-else>
              <ion-label class="ion-text-wrap">{{ language['test_information_sfl'] }}</ion-label>
            </li>

            <li v-if="settings.questions_per_page > 0">
              <ion-label class="ion-text-wrap">{{ language['test_information_qpp'] }}:
                <strong>{{ settings.questions_per_page }}</strong></ion-label>
            </li>

            <li v-if="settings['allow_click_previous'] === false">
              <ion-label class="ion-text-wrap">{{ language['test_information_cant_go_back'] }}</ion-label>
            </li>
            <li v-else>
              <ion-label class="ion-text-wrap">{{ language['test_information_can_go_back'] }}</ion-label>
            </li>

            <li v-if="settings.must_answer_question === false">
              <ion-label class="ion-text-wrap">{{
                  language['test_information_must_answer_all_false']
                }}
              </ion-label>
            </li>
            <li v-else>
              <ion-label class="ion-text-wrap">{{
                  language['test_information_must_answer_all_true']
                }}
              </ion-label>
            </li>

            <li v-if="settings.passmark != null && settings.passmark>0">
              <ion-label class="ion-text-wrap">{{ language['test_information_passmark'] }}: <strong>
                {{ settings.passmark }}%</strong></ion-label>
            </li>
          </ul>
        </ion-col>
      </ion-row>
      <ion-row v-if="data['test_instruction'] !== null && data['test_instruction'] !== ''" class="user-instructions">
        <html-content :content="data['test_instruction']"></html-content>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script setup>
import { computed, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/vue';
import HtmlContent from '@/components/html/HtmlContent.vue';
import timeUtil from '@/utils/timeUtil';
import constant from "@/constant";

const emit = defineEmits([
  'complete',
]);

const store = useStore();
const data = reactive(store.state.Test.data['test_intro']);
const settings = reactive(store.state.Test.settings['test']);
const language = reactive(store.getters.languageSet);

const { convertDurationString } = new timeUtil();
const isMonitoringBrowserEnabled = computed(() => store.getters['Monitoring/isMonitoringBrowserEnabled']);
const isMonitoringCameraEnabled = computed(() => store.getters['Monitoring/isMonitoringCameraEnabled']);
const isGroupTest = computed(() => store.getters['groupType'] === constant.GROUP_TYPE_GROUP);
const attemptsAllowed = computed(() => {
  return data['attempts_allowed'] === constant.ATTEMPTS_UNLIMITED ?
      language['test_information_unlimited_attempts'] :
      data['attempts_allowed'];
});

onMounted(() => {
  emit('complete');
});
</script>

<style lang="scss" scoped>
@import '@/theme/variables';

.generic-instructions {

  .pretest-instructions {
    padding: $medium-space $large-space $large-space;
    background-color: $color-gray-01;
    border-radius: $default-border-radius;
    margin: 0 0 32px;

    .title {
      font-weight: bold;
      margin-bottom: $medium-space;
    }

    ul {
      padding-left: 20px;

      li {
        color: $color-gray-primary-2;
        font-size: $default-font-size;


        &:not(:last-child) {
          margin-bottom: $small-space;
        }

        &::marker {
          font-weight: bold;
          color: $color-gray-primary;
          font-size: $medium-2-font-size;
        }

        & strong {
          margin-left: $small-space;
        }
      }
    }
  }

  .user-instructions {
    margin-bottom: 32px;
    overflow-x: auto;
  }
}
</style>