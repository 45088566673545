import constant from "../constant";
import store from '@/store';

/**
 * TestHelper is Helper script which contains functions not directly related to the UI actions
 *
 */

function __validateMatchingAnswer(user_answer) {

    let expected_num_answers = user_answer.expected_num_of_answer;
    let answered_num = 0;

    for (let key in user_answer.answer.user_answer) {
        let value = user_answer.answer.user_answer[key];
        if (value != null && value != '') {
            answered_num += 1;
        }
    }
    if (answered_num == 0) {
        return constant.QUESTION_NOT_ATTEMPT;
    } else if (answered_num < expected_num_answers) {
        return constant.QUESTION_NOT_ANSWERED;
    } else if (answered_num == expected_num_answers) {
        return constant.QUESTION_ANSWERED;
    }
}

function __validateEmptyText(user_answer) {
    if (user_answer.answer != null && user_answer.answer.trim() != '') {
        return constant.QUESTION_ANSWERED;
    }
    return constant.QUESTION_NOT_ANSWERED;
}

function __validateEmptyMultipleChoice(user_answer) {
    if (user_answer.answer != null && user_answer.answer.length > 0) {
        return constant.QUESTION_ANSWERED;
    }
    return constant.QUESTION_NOT_ANSWERED;
}

function validateUserAnswer(user_answer) {
    if (user_answer == null) {
        return constant.QUESTION_NOT_ANSWERED;
    }
    switch (user_answer.type) {
        case  constant.QUESTION_MATCHING:
            return __validateMatchingAnswer(user_answer);

        case constant.QUESTION_ESSAY:
        case constant.QUESTION_TRUE_FALSE:
        case constant.QUESTION_GRAMMAR:
        case constant.QUESTION_MULTIPLE_CHOICE:
        case constant.QUESTION_FREE_TEXT:
            return __validateEmptyText(user_answer);

        case constant.QUESTION_MULTIPLE_RESPONSE:
            return __validateEmptyMultipleChoice(user_answer);

    }
}

/**
 * Validate All User's answers
 * @returns {boolean}
 */
function validateAllAnswers() {
    let sections = store.state.Test.data.test.sections;
    let all_answered = true;

    for (let section of sections) {
        for (const [_, user_answer_in_page] of Object.entries(section.user_answers)) {

            // check user all user answers in a page
            for (const [_, user_answer] of Object.entries(user_answer_in_page)) {
                if (user_answer == null) {
                    all_answered = false;
                } else if (validateUserAnswer(user_answer) != constant.QUESTION_ANSWERED) {
                    all_answered = false;
                }
            }

            // if user has not answered question, stop processing
            if (!all_answered) {
                return all_answered;
            }
        }
    }

    return all_answered;
}


/**
 * Validate question is answered
 * - if test_settings.must_answer_question: all question should answered
 * - else: Matching question has to be empty or select all answers
 */
function validateQuestionAnswered(state, test_settings) {
    state.not_answered_quiz_ids = [];
    state.contents.contents.forEach((content) => {
        if (content.type == constant.CONTENTS_QUESTION) {
            let question_id = content.id;
            let question_id_int = parseInt(question_id);
            let user_answer = state.user_answers[question_id];
            let answer_status = validateUserAnswer(user_answer);

            if (test_settings.must_answer_question) {
                if (answer_status != constant.QUESTION_ANSWERED) {
                    if (!state.not_answered_quiz_ids.includes(question_id_int)) {
                        state.not_answered_quiz_ids.push(question_id_int);
                    }

                }
            }
            // else if (!test_settings.must_answer_question) {
            //     if (user_answer.type == constant.QUESTION_MATCHING
            //         && answer_status == constant.QUESTION_NOT_ANSWERED) {
            //         if (!state.not_answered_quiz_ids.includes(question_id_int)) {
            //             state.not_answered_quiz_ids.push(question_id_int);
            //         }
            //     }
            // }
        }
    });

    if (state.not_answered_quiz_ids.length > 0) {
        let error = {
            type: 'info',
            message: state.language_set['test_error_unanswered_text']
        };
        store.dispatch('Error/display', error);
        return false;
    }
    return true;


}

/**
 * Render the current Test Page
 */
function renderNewPage(store, state, route_handler) {
    state.display_question = false;

    if (store.getters.testState == constant.STATUS_TEST_IN_PROGRESS) {
        state.user_answers = store.getters.testPageUserAnswers;
        state.current_page = store.getters.getCurrentTestPageNumber;
        state.contents = store.getters.testPageContents;
        state.answer_updated = false;
        state.display_feedback = false;
        state.display_question = true;
        state.page_feedback = null;
        state.not_answered_quiz_ids = [];
    } else {
        route_handler.route();
    }

}

function scrollToQuestion(id) {

    let observer_interval = setInterval(function () {
        let detected = false;
        let question_id = '#question-' + id;
        let question_div = document.querySelector(question_id);
        if (question_div != null) {
            detected = true;
        }

        if (detected) {
            clearInterval(observer_interval);
            let question_div = document.querySelector(question_id);
            let scroll_position = question_div.offsetTop - 52; //-52 for adjust to show entire card
            let content = document.querySelector('ion-content');
            content.scrollToPoint(0, scroll_position, 0);


            // without focusing the question, voice over target is lost after changing the page
            // (The voice over utility focusing on void space).
            // To ensure voice over utility focusing to the target question, we need to focus the target question div.
            question_div.focus();


        }
    }, 200);
}


function scrollToFirstQuestion(state, offline_message) {
    // when offline message exists, scroll to the top
    if (offline_message.value != null) {
        let content = document.querySelector('ion-content');
        content.scrollToPoint(0, 0, 0);
    } else {
        //other wise scroll to the first question
        let first_question_id = state.contents.contents[0].id;
        scrollToQuestion(first_question_id);
    }
}

function focusFirstQuestion(id) {
    let question_id = '#question-' + id;
    let question_div = document.querySelector(question_id);
    question_div.focus();
}

function delayDisplayContents(state) {
    setTimeout(() => {
        state.display_contents = true;
    }, 500);
}

function displayLoadingSpinner(state, isLoadingSpinnerOpen) {
    let page_transition_timeout_id = setTimeout(() => {
        if (state.display_contents == false) {
            isLoadingSpinnerOpen.value = true;
        }
    }, 2000);
    return page_transition_timeout_id;
}

function dismissLoadingComponents(state, isLoadingSpinnerOpen) {
    isLoadingSpinnerOpen.value = false;
    state.disable_buttons = false;
}

function fadeContents(display_contents) {
    let contents_div = document.getElementById('test-contents');
    let test_header_button_div = document.getElementById('test-header-button-area');
    let test_header_timer_div = document.getElementById('test-header-timer');
    let logo_area_div = document.getElementById('logo-area');
    let div_list = [contents_div, test_header_button_div, test_header_timer_div, logo_area_div];

    // show contents
    if (display_contents) {
        div_list.forEach((div) => {
            if (div != null) {
                div.classList.remove('fade-leave-to');
                div.classList.add('fade-enter-to');
            }
        })
    } else {
        // hide contents
        div_list.forEach((div) => {
            if (div != null) {
                div.classList.remove('fade-enter-to');
                div.classList.add('fade-leave-to');
            }
        });
    }
}

function handleError(error) {
    if (store.state.Error.error.error_code == constant.ERROR_NO_INTERNET) {
        store.dispatch('Error/openModal');
    } else if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
        store.dispatch('Error/displayFromAPIError', error);
    } else {
        store.dispatch('Error/displayFromAPIError', error);
    }
}

function closeImageModal(){
    let modal = document.querySelector('.image-magnifier-modal')
    if(modal != null){
        modal.dismiss();
    }
}

export default {
    validateAllAnswers,
    validateQuestionAnswered,
    renderNewPage,
    validateUserAnswer,
    scrollToQuestion,
    scrollToFirstQuestion,
    displayLoadingSpinner,
    dismissLoadingComponents,
    delayDisplayContents,
    fadeContents,
    handleError,
    focusFirstQuestion,
    closeImageModal,
};
