import { ref, computed } from 'vue';
import { useSupported, usePermission, useEventListener } from '@vueuse/core';

/**
 * Options defined as vanilla object until we can support TypeScript interfaces.
 */
export const UseDeviceListOptions = {
  // Callback invoked when device list is updated
  onUpdated: null,
  // Request permissions immediately if not already granted
  requestPermissions: true,
  // Request for types of media permissions
  constraints: { audio: true, video: true },
}

/**
 * Reactively list available input/output devices and permissions.
 *
 * @param options
 */
export function useDeviceList(options = {}) {
  options = { ...UseDeviceListOptions, ...options };

  const navigator = window.navigator;
  const devices = ref([]);
  const videoInputs = computed(() => getDevicesByType('videoinput'));
  const audioInputs = computed(() => getDevicesByType('audioinput'));
  const audioOutputs = computed(() => getDevicesByType('audiooutput'));
  const isSupported = useSupported(() => navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices);
  const permissionGranted = ref(false);
  let stream = null;

  function getDevicesByType(type) {
    return devices.value.filter((device, index, list) => {
      return device.kind === type &&
        device.label !== '' &&
        list.findIndex((item) => item.deviceId === device.deviceId) === index;
    });
  }

  async function update() {
    if (!isSupported.value) {
      return;
    }

    devices.value = await navigator.mediaDevices.enumerateDevices();
    options.onUpdated?.(devices.value);
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      stream = null;
    }
  }

  async function ensurePermissions() {
    if (!isSupported.value) {
      return false;
    }

    if (permissionGranted.value) {
      return true;
    }

    const { state, query } = usePermission('camera', { controls: true });
    await query();
    if (state.value !== 'granted') {
      let granted = true;
      try {
        stream = await navigator.mediaDevices.getUserMedia(options.constraints);
      } catch {
        stream = null;
        granted = false;
      }
      update().then(() => {});
      permissionGranted.value = granted;
    } else {
      permissionGranted.value = true;
    }

    return permissionGranted.value;
  }

  if (isSupported.value) {
    if (options.requestPermissions) {
      ensurePermissions().then(() => {});
    }

    useEventListener(navigator.mediaDevices, 'devicechange', update);
    update().then(() => {});
  }

  return {
    devices,
    ensurePermissions,
    permissionGranted,
    videoInputs,
    audioInputs,
    audioOutputs,
    isSupported,
  }
}
