<template>
  <div class="camera-instructions">
    <ion-grid class="ion-no-padding">

      <ion-row class="instructions-row">
        <ion-col size="9" v-if="settings['proctor_camera_instructions'] !== null && settings['proctor_camera_instructions'] !== ''">
          <html-content :content="settings['proctor_camera_instructions']"></html-content>
        </ion-col>
        <ion-col size="9" v-else>
          <p><html-content :content="language['test_monitoring_camera_line_1']"></html-content></p>
          <p><html-content :content="language['test_monitoring_camera_line_2']"></html-content></p>
          <p><html-content :content="language['test_monitoring_camera_line_3']"></html-content></p>
        </ion-col>
        <ion-col>
          <ion-img v-if="settings['proctor_camera_initial_photo']" class="capture-photo-id"
                   :src="BASE_URL + '/static/images/monitoring-capture-photo-id.svg'" />
          <ion-img v-else class="capture-photo"
                   :src="BASE_URL + '/static/images/monitoring-capture-photo.svg'" />
        </ion-col>
      </ion-row>
      <ion-row v-if="cameras.length === 0">
        <ion-col>
          <Banner
              :display-icon="true"
              :message="language['test_monitoring_camera_disconnect_message']"
              banner-type="warning"
              style="margin-bottom: 15px;"/>
        </ion-col>
      </ion-row>
      <ion-row class="drop-shadow-row">
        <ion-col size="12">
          <CameraPhoto
              :capture="true"
              :stream="cameraStream"
              @captured="onCaptured"
              @retake="onRetake" />
        </ion-col>
        <ion-col size="12">
          <CameraDevice
              :id="cameraId"
              :label="cameraLabel"
              :cameras="cameras" @change="onCameraChange" />
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script setup>
import { ref, reactive, watch } from 'vue';
import {IonCol, IonGrid, IonImg, IonRow} from '@ionic/vue';
import { useStore } from 'vuex';
import { useMonitorCamera } from '@/core/useMonitor/camera';
import CameraPhoto from '@/components/test/CameraPhoto.vue';
import CameraDevice from '@/components/test/CameraDevice.vue';
import HtmlContent from '@/components/html/HtmlContent.vue';
import Banner from '@/components/html/Banner.vue';

const emit = defineEmits([
  'complete',
  'cancel',
]);

const BASE_URL = process.env.BASE_URL;

const store = useStore();
const data = reactive(store.state.Test.data['test_intro']);
const settings = reactive(store.state.Test.settings['test']);
const language = reactive(store.getters.languageSet);
const captured = ref(null);
const image = ref(null);

const {
  isCameraSupported,
  isCameraStreaming,
  isCameraPermissionGranted,
  cameras,
  cameraId,
  cameraLabel,
  cameraEnabled,
  cameraStream,
  cameraEvents,
  cameraConstraints,
  cameraAutoSwitch,
  startCamera,
  stopCamera,
  restartCamera,
} = useMonitorCamera({
  onConnect: function() {
    store.dispatch('Monitoring/setCameraConnected');
  },
  onDisconnect: function() {
    store.dispatch('Monitoring/setCameraDisconnected');
  },
  onError: function(error) {

  }
});

const onCameraChange = (id) => {
  cameraId.value = id;
};

const onCaptured = (source) => {
  image.value = source;
  emit('complete');
};

const onRetake = () => {
  image.value = null;
  emit('cancel');
};

const submitImage = async () => {
  const event  = {
    type: 'intro',
    data: image.value,
    base_64: true
  };
  return await store.dispatch('Monitoring/submitImage', event);
};

watch(cameraId, () => {
  store.dispatch('Monitoring/setCameraId', cameraId.value);
});

defineExpose({
  submitImage
});

</script>

<style lang="scss" scoped>
@import '@/theme/variables';

.camera-instructions {

  .instructions-row {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .drop-shadow-row {
    display: flex;
    max-width: 668px;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 10px;
    border: 1px solid #E9ECEF;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
  }
}

</style>