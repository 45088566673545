<template>
  <ion-card class="timer">

    <CircleProgress :percentage="progressRatio * 100"></CircleProgress>
    <span>
        <strong>{{ state.language_set.test_time_left }}: </strong>
        <span class="test-time-left" data-cy="test-time-left">{{
            convertDurationString(timeoutRemainingTime)
          }}</span>
    </span>

  </ion-card>
</template>

<script>
import {IonCard, IonIcon, IonProgressBar} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import store from "@/store";
import timeUtil from "../../utils/timeUtil";
import CircleProgress from "@/components/html/CircleProgress.vue";

export default defineComponent({
  name: 'TimerCard',
  components: {CircleProgress, IonIcon, IonProgressBar, IonCard},
  props: {
    progressRatio: Number,
    timeoutRemainingTime: Number
  },
  setup(props, ctx) {
    const state = reactive({
      language_set: store.getters.languageSet,
    });
    const {convertDurationString} = new timeUtil();
    return {state, convertDurationString}
  }
});
</script>

<style lang="scss" scoped>
@import '../../theme/classmarker_theme';

.timer {
  margin: 0px;
  height: 36px;
  gap: 10px;
  border-radius: 100px;
  border-width: 1px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 11px;

  display: inline-flex;
  flex-direction: row;

  align-items: center;



  .clock {
    position: relative;
    top: 2px;
    margin-right: 9px;
  }


}

html[dir=rtl] {
  .timer {
    .clock {
      margin-right: auto;
      margin-left: 9px;
    }

    .test-time-left {
      float: left;
    }
  }
}

</style>