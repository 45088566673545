export const DEVICE_IPAD = 'ipad'
export const DEVICE_IPHONE = 'iphone';
export const DEVICE_ANDROID = 'android';

export default class MobileDetector {

    constructor() {
        this.is_mobile = false;
        this.device_type = null;
    }


    __hasDeviceTouchScreen() {
        let hasTouchScreen = false;

        if ("maxTouchPoints" in navigator) {
            hasTouchScreen = navigator.maxTouchPoints > 0;
        } else if ("msMaxTouchPoints" in navigator) {
            hasTouchScreen = navigator.msMaxTouchPoints > 0;
        } else {
            const mQ = matchMedia?.("(pointer:coarse)");
            if (mQ?.media === "(pointer:coarse)") {
                hasTouchScreen = !!mQ.matches;
            } else if ("orientation" in window) {
                hasTouchScreen = true; // deprecated, but good fallback
            }
        }

        return hasTouchScreen;
    }


    __isMobileDeviceUserAgent() {
        const userAgent = navigator.userAgent;

        if (/\b(iPhone|iPod)\b/i.test(userAgent)) {
            console.debug('iPhone detected via user agent');
            this.__setIPhone();
        } else if (/\b(BlackBerry|webOS|IEMobile|Windows Phone)\b/i.test(userAgent)) {
            this.is_mobile = true;
            this.device_type = null;
            console.debug('mobile device detected via user agent');

        } else if (/\b(Android)\b/i.test(userAgent)) {
            this.__setAndroid()
            console.debug('android device detected via user agent');
        } else if (/\b(iPad)\b/i.test(userAgent)) {
            this.__setIPad();
            console.debug('iPad device detected via user agent');
        }
        return this.is_mobile;
    }

    /**
     * Detect if the device using windows operating system or not
     * ref: https://learn.microsoft.com/en-us/microsoft-edge/web-platform/how-to-detect-win11
     * Firefox and IE11 is not supported
     */
    __isWindowsMachine() {

        //for chrome
        if (navigator.userAgentData !== undefined) {
            navigator.userAgentData.getHighEntropyValues(["platformVersion"])
                .then(() => {
                    if (navigator.userAgentData.platform === "Windows") {
                        console.debug('windows via platform')
                        this.__setWindows();
                        return true;
                    } else {
                        return false;
                    }
                });
            // for firefox
        } else if (navigator.userAgent !== undefined) {
            let userAgent = navigator.userAgent
            if (userAgent.toLowerCase().includes('windows')) {
                console.debug('windows via userAgent')
                this.__setWindows();
                return true;
            }
        }
        return false;
    }


    /**
     * To check IPad Device, we would like to set up device
     * @returns {boolean}
     */
    __isIPadDevice() {

        let monitor_width = screen.width;
        let monitor_height = screen.height;
        console.debug("monitor_width: %s, monitor_height %s", monitor_width, monitor_height);

        switch (monitor_width) {
            case 744:
                if (monitor_height == 1133) {
                    this.__setIPad(); // iPad Mini(A17 pro, 6th)
                }
                break;

            case 768:
                if (monitor_height == 1024) {
                    this.__setIPad(); // iPad Mini(5th,4th, 3rd, 2nd, 1st), iPad(6th, 5th, 4th,3rd,2nd, 1st), Pro 9.7", Air(1st, 2nd)
                }
                break;

            case 810:
                if (monitor_height == 1180) {
                    this.__setIPad(); //iPad (9th, 8th, 7th)
                }
                break;

            case 820:
                if (monitor_height == 1180) {
                    this.__setIPad(); //iPad Air 11" (m3,  M2,5th, 4th), iPAD(A16, 10th)
                }
                break;

            case 834:
                if (monitor_height == 1210) {
                    this.__setIPad() //iPad Pro 11" (m4)
                } else if (monitor_height == 1112) {
                    this.__setIPad() //iPad Air (3rd) iPad Pro 10.5"
                } else if (monitor_height == 1194) {
                    this.__setIPad() //iPad Pro 11" (4th, 3rd, 2nd, 1st)
                }
                break;

            case 1024:
                if (monitor_height == 1366) {
                    this.__setIPad(); //iPad Air 13" (M3, M2),  iPad PRO 12.9" (6th, 5th,4th, 3rd, 2nd, 1st)
                }
                break;

            case 1032:
                if (monitor_height == 1376) {
                    this.__setIPad() //iPad Pro 13" (m4)
                }
                break;
        }


        let result = this.device_type == DEVICE_IPAD ? true : false;
        console.debug('iPad device detected via screen resolution: ' + result);
        return result;


    }

    __setIPad() {
        this.is_mobile = true;
        this.device_type = DEVICE_IPAD //iPad Air 11" (m3,  M2,5th, 4th), iPAD(A16, 10th)
    }

    __setIPhone() {
        this.is_mobile = true;
        this.device_type = DEVICE_IPHONE //iPad Air 11" (m3,  M2,5th, 4th), iPAD(A16, 10th)
    }

    __setAndroid() {
        this.is_mobile = true;
        this.device_type = DEVICE_ANDROID //iPad Air 11" (m3,  M2,5th, 4th), iPAD(A16, 10th)
    }

    __setWindows() {
        this.is_mobile = false;
        this.device_type = null;
    }


    detect() {
        /**
         * Most of mobile devices has touch screen.
         * The first step of screening process is to check weather the device has touch screen.
         * Then we check the device user agent (which still risky) that the client are in android, ipad,Iphone from the user agent.
         * if not we check user agent with windows machine or not
         * then check with iPad using screen resolution.
         * None of passed then we set as android.
         *
         *
         * ** The potential risk is: if someone has touch screen with PC and use inspector to manipulate the user agent,
         * we have no way to detect it for now.
         */


        // always first check the device has touch screen or not.
        // we skip this process for "development" environment for the easier development process
        if (process.env.VUE_APP_MODE !== 'development' && this.__hasDeviceTouchScreen() === false) {

            return false;
        }

        // check if mobile device has user agent
        if (this.__isMobileDeviceUserAgent()) {
            if (this.device_type === null) {
                console.log(2);
                this.__isIPadDevice() // check if this is iPad Device
            }

            return this.is_mobile;
        } else {
            if (this.__isWindowsMachine()) {

                return false;
            } else if (this.__isIPadDevice()) {

                return true;
            } else {
                // we check device has touch screen or not if the environment is development (that is skipped from the top).
                if(process.env.VUE_APP_MODE === 'development' && this.__hasDeviceTouchScreen() === false){
                    return false;
                }

                this.__setAndroid();
                return true;
            }
        }

    }
}