<template>

  <TestTitle :display-price="false"
             v-if="currentInstruction.name  === 'generic'|| currentInstruction.name === 'custom'"></TestTitle>
  <MonitorInstructionTitle :display-price="false" v-else>

  </MonitorInstructionTitle>


  <GenericInstructions
      v-if="currentInstruction.name === 'generic'"
      @complete="onInstructionComplete"/>
  <CustomInstructions
      v-if="currentInstruction.name === 'custom'"
      @complete="onInstructionComplete"/>
  <BrowserInstructions
      v-if="currentInstruction.name === 'browser'"
      @complete="onInstructionComplete"/>
  <CameraInstructions
      v-if="currentInstruction.name === 'camera'"
      ref="cameraInstruction"
      @complete="onInstructionComplete"
      @cancel="onInstructionCancel"/>
  <div class="button-area">
    <ion-button
        class="ion-float-left"
        data-cy="previous-btn"
        @click="previousButtonClick"
        v-if="previousButtonVisible"
        :disabled="!previousButtonEnabled">
      {{ previousButtonLabel }}
    </ion-button>
    <ion-button
        class="ion-float-right"
        data-cy="continue-btn"
        @click="nextButtonClick"
        v-if="nextButtonVisible"
        :disabled="!nextButtonEnabled">
      {{ nextButtonLabel }}
    </ion-button>
  </div>
</template>

<script setup>
import {computed, reactive, ref} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {IonButton} from '@ionic/vue';
import GenericInstructions from '@/components/test-intro/instructions/GenericInstructions.vue';
import CustomInstructions from '@/components/test-intro/instructions/CustomInstructions.vue';
import BrowserInstructions from '@/components/test-intro/instructions/BrowserInstructions.vue';
import CameraInstructions from '@/components/test-intro/instructions/CameraInstructions.vue';
import RouteHandler from '@/router/routeHandler';
import constant from "@/constant";
import TestTitle from "@/components/html/TestTitle.vue";
import MonitorInstructionTitle from "@/components/test-intro/instructions/MonitorInstructionTitle.vue"

const store = useStore();
const loading = ref(false);
const data = reactive(store.state.Test.data['test_intro']);
const settings = reactive(store.state.Test.settings['test']);
const language = reactive(store.getters.languageSet);
const routeHandler = new RouteHandler(useRouter());

const cameraInstruction = ref(null);

const instructions = [
  {
    name: 'generic',
    order: 1,
    isComplete: ref(false),
    isAvailable: computed(() => {
      return settings['display_pretest_instruction'] === true ||
          (data['test_instruction'] !== null && data['test_instruction'] !== '');
    })
  },
  {
    name: 'custom',
    order: 2,
    isComplete: ref(false),
    isAvailable: computed(() => {
      return data['display_pretest_custom_instruction'] === true;
    })
  },
  {
    name: 'browser',
    order: 3,
    isComplete: ref(false),
    isAvailable: computed(() => {
      return store.getters['Monitoring/isMonitoringBrowserEnabled'];
    })
  },
  {
    name: 'camera',
    order: 4,
    isComplete: ref(false),
    isAvailable: computed(() => {
      return store.getters['Monitoring/isMonitoringCameraEnabled'];
    }),
    onContinue: async function () {
      return cameraInstruction.value?.submitImage();
    }
  }
];

const availableInstructions = computed(() => {
  return instructions
      .filter(instruction => instruction.isAvailable.value)
      .sort((a, b) => a - b);
});

const allInstructionsComplete = computed(() => {
  return instructions.filter(instruction => !instruction.isComplete.value).length === 0;
});

const currentInstructionIndex = ref(0);
const currentInstruction = computed(() => {
  return availableInstructions.value[currentInstructionIndex.value];
});

const onInstructionComplete = () => {
  currentInstruction.value.isComplete.value = true;
};

const onInstructionCancel = () => {
  currentInstruction.value.isComplete.value = false;
};

const previousButtonLabel = computed(() => {
  return language['link_previous'];
});
const previousButtonEnabled = computed(() => {
  return !loading.value;
});
const previousButtonVisible = computed(() => {
  if (availableInstructions.value.length > 1 && currentInstructionIndex.value > 0) {
    return true;
  }
  const loginSettings = store.getters['loginSetting'];
  return !!(loginSettings['first_name_required'] ||
      loginSettings['last_name_required'] ||
      loginSettings['email_required'] ||
      loginSettings['user_extra_info_required']);
});
const previousButtonClick = () => {
  if (currentInstructionIndex.value > 0) {
    currentInstructionIndex.value--;
  } else {
    routeHandler.goBack();
  }
};
const nextButtonLabel = computed(() => {
  return currentInstructionIndex.value + 1 === availableInstructions.value.length ?
      language['link_start_test'] : language['link_continue'];
});
const nextButtonEnabled = computed(() => {
  return !loading.value && currentInstruction.value.isComplete.value;
});
const nextButtonVisible = computed(() => {
  return true;
});
const nextButtonClick = () => {
  const startTest = () => {
    loading.value = true;
    store.dispatch('Test/startTest').then(() => {
      loading.value = false;
      routeHandler.route();
    }).catch((error) => {
      loading.value = false;
      if (store.state.Error.error.error_code === constant.ERROR_NO_INTERNET) {
        store.dispatch('Error/openModal');
      } else {
        store.dispatch('Error/displayFromAPIError', error);
      }
    }).finally(() => loading.value = false);
  };

  if (currentInstructionIndex.value + 1 < availableInstructions.value.length) {
    if (currentInstruction.value.hasOwnProperty('onContinue')) {
      loading.value = true;
      currentInstruction.value.onContinue().then((success) => {
        if (success) {
          currentInstructionIndex.value++;
        }
      }).finally(() => loading.value = false);
    } else {
      currentInstructionIndex.value++;
    }
  } else {
    if (currentInstruction.value.hasOwnProperty('onContinue')) {
      loading.value = true;
      currentInstruction.value.onContinue().then((success) => {
        if (success) {
          startTest();
        }
      }).finally(() => loading.value = false);
    } else {
      startTest();
    }
  }
};

</script>

<style lang="scss" scoped>


:deep(.button-area) {
  margin-top: 0;
}



</style>