<template>
  <ion-app>
    <div>
      <router-view v-if="!displayErrorPage && offline_status == STATUS_ONLINE && reach_sim_user_limit == false"
                   v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component"></component>
        </transition>
      </router-view>

      <offline-mode
          v-else-if="!displayErrorPage && offline_status != STATUS_ONLINE && reach_sim_user_limit == false"></offline-mode>
      <sim-user-limit-message
          v-else-if="!displayErrorPage && offline_status != STATUS_ONLINE && reach_sim_user_limit"></sim-user-limit-message>
      <ErrorPage v-else></ErrorPage>

      <ion-modal
          :is-open="displayErrorModal"
          :backdrop-dismiss="false"
          :swipe-to-close="false"
          :css-class="$setComponentOrientation('global-error-modal')">
        <GlobalErrorModal></GlobalErrorModal>
      </ion-modal>
      <MonitoringErrorModal />
<!--  // TODO: to fix monitoring camera to work with global error message page -->
    <MonitoringCamera v-if="isMonitoringCamera" />

      <PreviewModeBanner v-if="preview_mode == true"></PreviewModeBanner>

      <div id="print-content">
      </div>
    </div>
  </ion-app>
</template>

<script>
import {IonApp, IonModal, IonRouterOutlet} from '@ionic/vue';
import {computed, defineComponent, provide, reactive, watch} from 'vue';
import {mapGetters} from "vuex";
import GlobalErrorModal from "./components/html/GlobalErrorModal";
import MonitoringErrorModal from "./components/html/MonitoringErrorModal.vue";
import MonitoringCamera from "@/components/html/MonitoringCamera.vue";
import store from "@/store";
import htmlSetupUtil from "./utils/htmlSetupUtil";
import ErrorPage from "./views/ErrorPage";
import PreviewModeBanner from "./components/html/PreviewModeBanner";
import OfflineMode from "./components/test/OfflineMode";
import constant from "./constant";
import googleTagManagerUtils from "./utils/googleTagManagerUtils";
import SimUserLimitMessage from "@/components/test/SimUserLimitMessage.vue";
import {useMonitorBrowser, UseMonitorBrowserOptions} from "@/core/useMonitor/browser";

import MobileDetector from "./utils/mobileDetector";

export default defineComponent({
  name: 'App',
  components: {
    MonitoringCamera,
    MonitoringErrorModal,
    OfflineMode,
    ErrorPage,
    IonApp,
    IonRouterOutlet,
    GlobalErrorModal,
    IonModal,
    PreviewModeBanner,
    SimUserLimitMessage
  },
  mounted() {
    // remove initial loader
    let loader = document.querySelector("#initial_loader");
    if (loader != null) {
      loader.parentElement.removeChild(loader);
    }

    const {setupLanguage} = htmlSetupUtil();
    // // disable copy & paste if it is required.
    // disablePaste();
    // disableCopy();
    setupLanguage();

    /**   Print Pages - Override Ionic Css attributes using javascript     ***********************
     *
     * Ideally we shouldn't copy the content of the pages into extra div (#print-content) only for print purpose.
     * However some reasons, the questions are overlapped and couldn't find a solution except this way.
     *
     *
     * Below code copy the content of "ion-content" and past into "#print-content" before printing.
     * After print is done, the content of "#print-content" is removed
     * **/


    function beforePrint() {
      let settings = store.getters.testSettings;
      if (settings != null && settings.print_allowed && !store.state.print_button_clicked) {
        let ion_content = document.querySelector('ion-content');
        let print_content = document.querySelector('#print-content');
        print_content.innerHTML = ion_content.innerHTML;
      }
    }

    function afterPrint() {
      let print_content = document.querySelector('#print-content');
      print_content.innerHTML = '';
    }


    window.onbeforeprint = function (event) {
      beforePrint()
    }
    window.onafterprint = function (event) {
      afterPrint()
    }

    /************************************************************************************************/

    if (!this.isMonitoringBrowserEnabled) {
      window.addEventListener('beforeunload', this.$popupUnLoadEvent);
    }

  },
  beforeUnmount() {
    if (!this.isMonitoringBrowserEnabled) {
      window.removeEventListener('beforeunload', this.$popupUnLoadEvent);
    }
  },
  computed: {
    ...mapGetters('Monitoring', [
        'isTestHalted',
        'isMonitoringBrowser',
        'isMonitoringBrowserEnabled',
        'isMonitoringCamera',
        'isMonitoringCameraEnabled',
    ])
  },
  watch: {
    isMonitoringBrowser: {
      handler(enabled) {
        if (enabled) {
          this.startMonitoring();
        } else {
          this.stopMonitoring();
        }
      },
      immediate: true
    },
    isTestHalted(halted) {
      if (this.isMonitoringBrowser && !halted) {
        this.resumeMonitoring();
      }
    },
  },
  setup() {
    const state = reactive({})

    const STATUS_ONLINE = constant.STATUS_ONLINE;

    // flag to show error page
    const displayErrorPage = computed(() => {
      return store.getters.displayErrorPage;
    })

    // flag to show error modal
    const displayErrorModal = computed(() => {
      return store.getters.displayErrorModal;
    });

    // test settings
    const settings = store.getters.testSettings;

    // flag to show preview mode
    const preview_mode = computed(() => {
      return store.getters.previewMode
    });

    //constant offline status
    const offline_status = computed(() => {
      return store.getters.offlineStatus;
    })


    const reach_sim_user_limit = computed(() => {
      return store.getters.reachSimUserLimit;
    })

    // SETUP Themes properties
    const theme = store.getters.theme;

    if (theme != null && theme.show_ads) {
      provide('show_ads', true)
      if (theme.test_title != null) {
        document.title = 'Quiz Maker for ' + theme.test_title;
      }
    } else {
      provide('show_ads', false)
      if (theme.test_title != null) {
        document.title = theme.test_title;
      }
    }


    googleTagManagerUtils().pushEventData("Initiate Test", "");

    let body_background_css = '';

    if (theme.body_bg_color) {
      body_background_css = body_background_css + theme.body_bg_color;
    }

    if (theme.body_bg_img_url != null) {
      if (theme.body_bg_img_repeat) {
        body_background_css = body_background_css + ' ' + theme.body_bg_img_repeat;
      }
      body_background_css = body_background_css + " url('" + theme.body_bg_img_url + "')";
    }

    let header_log_custom_img_css = 'margin-left: ' + theme.header_content_align + ';';
    if (theme.header_content_align == '0px') {
      header_log_custom_img_css = header_log_custom_img_css + 'padding-left: 0px;';
    }


    let css_style = `
    ion-content {
      --background: ${body_background_css};
    }

    a {
      color: ${theme.default_button_color} !important;
    }

    ion-button {
      --background: ${theme.default_button_color};
    }

    ion-button.finish-button {
      --background: ${theme.finish_button_color} ;
    }

    ion-button.certificate-button {
      --background: ${theme.certificate_button_color} ;
    }

    ion-button.see-all-question-button {
      color: ${theme.see_all_question_button_color};
      --background:transparent;
    }

    ion-button.continue-button {
      --background: ${theme.return_button_color};
    }

    .template {
      margin-left: ${theme.body_content_align} !important;
    }

    #test-header{
       background-color: white;
    }

    #test-header .header{
       background-color:white !important;
    }

    .header {
      background-color: ${theme.header_bg_color};
      margin-left: ${theme.body_content_align};
      max-width: ${theme.header_width};
      margin-right: auto;
      position: relative;
    }

    .header .header__logo_custom h1 {
      padding: 16px;
      color: ${theme.header_text_color};
      background-color: ${theme.header_bg_color};
      text-align: ${theme.header_text_align};
    }

    .header .header__logo_custom img{
        ${header_log_custom_img_css}
    }`;

    let head = document.head || document.getElementsByTagName('head')[0];
    let style = document.createElement('style');

    head.appendChild(style);
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css_style));


    watch(() => displayErrorPage, (to, from) => {
      /** when error page is displayed, we need to make sure few things.
       * 1. clear test auto submit timer
       * 2. send google analytics data
       */

      if (to.value == true) {

        store.dispatch('clearTestAutoSubmitTimer');
      }
    }, {deep: true});


    let mobile_detector = new MobileDetector();
    mobile_detector.detect();

    store.dispatch('setIsMobileDevice', mobile_detector.is_mobile);
    store.dispatch('setDeviceType', mobile_detector.device_type)

    if (mobile_detector.is_mobile) {
      console.debug('mobile device detected: disable mouse outside detection & full screen & focus enable')
      UseMonitorBrowserOptions.outsideEnabled = false;
      UseMonitorBrowserOptions.fullscreenEnabled = false;
      UseMonitorBrowserOptions.focusedEnabled = false;
    }

    const {
      isMonitoring,
      isFullscreen,
      isFullscreenSupported,
      exitFullscreen,
      startMonitoring,
      resumeMonitoring,
      stopMonitoring,
    } = useMonitorBrowser({
      onError: (error) => {
        store.dispatch('Monitoring/haltTest', error, {root: true});
      }
    });

    return {
      state,
      displayErrorModal,
      displayErrorPage,
      preview_mode,
      offline_status,
      STATUS_ONLINE,
      reach_sim_user_limit,
      isMonitoring,
      startMonitoring,
      resumeMonitoring,
      stopMonitoring,
    };
  }
});

</script>

<style lang="scss" scoped>

</style>
