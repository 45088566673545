import testClient from "@/api/testClient";
import {useMonitorLogging} from '@/core/useMonitor/logging';

const {closeLostEventAfterHalting} = useMonitorLogging();

export const MonitoringModule = {
    namespaced: true,
    state: {
        error: null,
        cameraId: null,
        complete: false,
        snapshots: [],
    },
    mutations: {
        SET_ERROR(state, error) {
            state.error = error;
        },
        CLEAR_ERROR(state) {
            state.error = null;
        },
        SET_COMPLETE(state) {
            state.complete = true;
        },
        SET_CAMERA_ID(state, cameraId) {
            state.cameraId = cameraId;
        },
        SET_CAMERA_CONNECTED(state) {
            state.cameraConnected = true;
        },
        SET_CAMERA_DISCONNECTED(state) {
            state.cameraConnected = false;
        },
        SET_MONITORING(state, data) {
            if (data.hasOwnProperty('snapshots') && !state.snapshots.length) {
                state.snapshots = data.snapshots;
            }
        },
        SET_SNAPSHOT_CAPTURED_BY_ID(state, id) {
            state.snapshots.forEach((snapshot) => {
                if (!snapshot.captured && snapshot.id === id) {
                    snapshot.captured = true;
                }
            });
        },
    },
    actions: {
        haltTest({commit, dispatch}, error) {
            dispatch('Test/setBookmarkModalVisibility', false, {root: true});
            commit('SET_ERROR', error);
        },
        unHaltTest({commit}) {
            closeLostEventAfterHalting().then(() => {
                commit('CLEAR_ERROR');
            });
        },
        setComplete({commit}) {
            commit('SET_COMPLETE');
        },
        setCameraId({commit}, cameraId) {
            commit('SET_CAMERA_ID', cameraId);
        },
        setCameraConnected({commit}) {
            commit('SET_CAMERA_CONNECTED');
        },
        setCameraDisconnected({commit}) {
            commit('SET_CAMERA_DISCONNECTED');
        },
        setSnapshotCapturedById({commit}, id) {
            commit('SET_SNAPSHOT_CAPTURED_BY_ID', id);
        },
        async submitEvent({commit, rootState, dispatch}, event) {
            const response = await testClient.submitMonitorEvent(event)
            return response.data.data['total_lost_events'];
        },
        async submitImage({commit, rootState, dispatch}, event) {
            const response = await testClient.submitMonitorImage(event)
            return response.data.data['result'] === 'ok';
        },
    },
    getters: {
        isTestHalted: state => state.error !== null,
        isMonitoringComplete: state => state.complete,
        isMonitoringBrowserEnabled(state, getters, rootState) {
            let result = rootState.Test.settings.test?.proctor_user_monitor_mode ?? false;
            return result;
        },
        isMonitoringBrowser(state, getters, rootState, rootGetters) {
            return getters.isMonitoringBrowserEnabled &&
                !getters.isMonitoringComplete &&
                rootGetters['Test/isInProgress'];
        },
        isMonitoringCameraEnabled(state, getters, rootState) {
            let result = rootState.Test.settings.test?.proctor_camera_mode ?? false;
            return result
        },
        isMonitoringCamera(state, getters, rootState, rootGetters) {
            return getters.isMonitoringCameraEnabled &&
                !getters.isMonitoringComplete &&
                (rootGetters['Test/isInProgress'] || rootGetters['Test/isComplete']);
        },
        getSnapshotById: (state) => (id) => {
            return state.snapshots.find(snapshot => snapshot.id === id) || null;
        },
        getSnapshotByType: (state) => (type) => {
            return state.snapshots.find(snapshot => snapshot.type === type) || null;
        },
        getSnapshotsNotCaptured: (state) => state.snapshots.filter(snapshot => !snapshot.captured),
    },
};

