import httpClient from "@/api/httpClient";
import responseHandler from "./responseHandler";
import TestData from "./model/testData";
import testClient from "../api/testClient";
import TestSection from "./model/testSection";
import ThemeData from "./model/themeData";
import paymentClient from "../api/paymentClient";
import constant from "../constant";
import cookie from "../utils/cookie";
import iframeMessageUtils from "../utils/iframeMessageUtils";

export const TestModule = {
    namespaced: true,
    state: {
        quiz_id: null,
        group_type: null,
        test_state: null,
        visible_questions: [],
        is_bookmark_modal_visible: false,
        settings: {
            login: null,
            test: null,
            theme: null,
        },
        data: {
            test_intro: null,
            login: null,
            user: null,
            price: null,
            test: null,
            result: null,
        },
    },
    // Mutations are functions that effect the state. Never called by developer, it is called by actions function
    mutations: {
        SET_TEST_STATE(state, data) {
            let test_state = data.test_state;
            let iframe_mode = data.iframe_mode;

            if (state.test_state == constant.STATUS_TEST_IN_PROGRESS && test_state == constant.STATUS_TEST_COMPLETE) {

                // if the test is not preview mode and attempts are restricted then increase the attempts.
                if (state.settings.test.group_type == constant.GROUP_TYPE_LINK && state.settings.test.preview_mode == false && state.settings.login.attempts_allowed != null) {
                    if (iframe_mode) {

                        const {increaseAttemptsIframeCookie} = iframeMessageUtils();
                        increaseAttemptsIframeCookie(state.quiz_id);

                    } else {

                        let cookie_name = constant.CACHE_ATTEMPTS_COUNT_PREFIX + state.quiz_id;
                        cookie.incrementBase64(cookie_name);

                    }
                }
            }
            state.test_state = test_state
        },
        SET_SETTINGS(state, settings) {

            if ('login' in settings && settings['login'] != null) {
                state.settings['login'] = settings['login'];
            }
            if ('test' in settings && settings['test'] != null) {
                state.settings['test'] = settings['test'];
            }
            if ('theme' in settings && settings['theme'] != null) {
                // state.settings['theme'] = settings['theme'];
                state.settings['theme'] = new ThemeData(settings['theme'])
            }

        },
        SET_EXPIRED_TIMESTAMP(state, data) {
            if ('test' in data) {
                let test_settings = state.settings['test'];
                let testData = new TestData(data, test_settings);

                // When timeout_timestamp updated from B.E, it need to applied in F.E to ensure timeout working same with.
                if (data.test.timeout_timestamp !== null && data.test.timeout_timestamp != test_settings.timeout_timestamp) {
                    test_settings.timeout_timestamp = data.test.timeout_timestamp;
                }

                // Remaining time calculated from server
                if (data.test.remaining_time !== null && data.test.remaining_time != test_settings.remaining_time) {
                    test_settings.remaining_time = data.test.remaining_time;
                }
            }
        },
        SET_REMAINING_TIME(state, remaining_time) {
            const test_settings = state.settings['test'];
            test_settings.remaining_time = remaining_time;
        },
        SET_DATA(state, data) {
            if (data['update_timeout_timestamp_only']) {

            }
            if ('test_intro' in data) {

                state.data.test_intro = data['test_intro'];
            }

            if ('login' in data) {

                state.data.login = data['login'];
            }

            if ('price' in data) {
                state.data.price = data['price'];
            }

            if ('test' in data) {
                let test_settings = state.settings['test'];
                let testData = new TestData(data, test_settings);

                this.commit('Test/SET_EXPIRED_TIMESTAMP', data);
                if (state.data.test === null) {
                    state.data.test = testData;
                } else {
                    state.data.test.updateData(testData, test_settings);
                    state.data.test.setPageFeedback(data);
                }
            }

            if ('result' in data) {
                state.data.result = data['result'];
                let section_result = [];
                let sections = data['result']['test_sections'];
                for (let i = 0; i < sections.length; i++) {
                    let test_settings = state.settings['test'];
                    let section = new TestSection(sections[i], test_settings);
                    section_result.push(section);
                }
                state.data.result.test_sections = section_result;
            }

        },
        DELETE_PAGE_FEEDBACK(state) {
            state.data.test.deletePageFeedback();
        },
        CLEAR_TEST_DATA(state) {
            state = {
                data: {
                    test_intro: null,
                    user: null,
                    test: null,
                    login: null,
                    price: null
                },
            };
        },
        SET_CURRENT_PAGE(state, {section_index, page_index}) {
            state.data.test.current_section = section_index;
            state.data.test.current_page = page_index;
        },
        SET_QUIZ_ID(state, quiz_id) {
            state.quiz_id = quiz_id;
        },
        SET_THEME(state, theme) {
            if (theme != null) {
                state.settings['theme'] = new ThemeData(theme)
            }
        },
        SET_GROUP_TYPE(state, group_type) {
            state.group_type = group_type;
        },
        SET_QUESTION_VISIBILITY(state, { questionId, visible }) {
            if (visible && ! state.visible_questions.includes(questionId)) {
                state.visible_questions.push(questionId);
            }
            if (! visible && state.visible_questions.includes(questionId)) {
                state.visible_questions.splice(state.visible_questions.indexOf(questionId), 1);
            }
        },
        CLEAR_VISIBLE_QUESTIONS(state) {
            state.visible_questions = [];
        },
        SET_BOOKMARK_MODAL_VISIBILITY(state, visible) {
            state.is_bookmark_modal_visible = visible;
        },
    },


    // Actions are functions that you call through your application that call mutations.
    actions: {
        /**
         * Get the initial of new test or the last state of ongoing test for the user
         * This should run once, when initialise app, so has to be synchronous (return promise)
         * @param commit
         * @param rootState
         * @returns {Promise<void>}
         */
        initState({commit, rootState, dispatch}, payload) {


            const promise = testClient.init(rootState.Auth.token, payload)
                .then((response) => {
                    responseHandler.setResponseData(commit, response);
                })
                .catch((error) => {
                    responseHandler.handleError(error, rootState.app_variables);
                    throw error;
                });
            return promise;
        },

        setState({commit, rootState}, test_state) {
            commit('SET_TEST_STATE', {test_state: test_state, iframe_mode: rootState.iframe_mode});
        },

        startTest({commit, rootState, dispatch}) {
            const promise = httpClient.post(rootState.Auth.token, '/test/start', null)
                .then((response) => {
                        if (response.data.state == 'test_in_progress') {
                            responseHandler.setResponseData(commit, response);
                        }
                    }
                ).catch((error) => {
                    responseHandler.handleError(error, rootState.app_variables);
                });

            return promise;
        },

        getPreviousPage({commit, rootState, dispatch}, payload) {
            commit('CLEAR_VISIBLE_QUESTIONS');
            const promise = testClient.previousPage(rootState.Auth.token, payload.current_page, payload.user_answers)
                .then((response) => {
                    responseHandler.setResponseData(commit, response);
                }).catch((error) => {
                    responseHandler.handleError(error, rootState.app_variables);
                });
            return promise;
        },

        submitAnswer({commit, rootState, dispatch}, payload) {
            commit('CLEAR_VISIBLE_QUESTIONS');
            const promise = testClient.nextPage(rootState.Auth.token, payload.current_page, payload.user_answers)
                .then((response) => {
                    responseHandler.setResponseData(commit, response);
                }).catch((error) => {
                    responseHandler.handleError(error, rootState.app_variables);
                });
            return promise;
        },

        autoSubmitAnswer({commit, rootState, dispatch}, payload) {

            if (payload.timeout == true) {
                const promise = testClient.autoComplete(rootState.Auth.token, payload.current_page, payload.user_answers)
                    .then((response) => {
                        responseHandler.setResponseData(commit, response);
                    }).catch((error) => {
                        responseHandler.handleError(error, rootState.app_variables);
                    });
                return promise;
            } else {
                const promise = testClient.autoCommit(rootState.Auth.token, payload.current_page, payload.user_answers)
                    .then((response) => {

                        if(response.data.state == constant.STATUS_TEST_COMPLETE){
                            responseHandler.setResponseData(commit, response, false);
                        }else{
                            responseHandler.setResponseData(commit, response, true);
                        }

                    }).catch((error) => {
                        responseHandler.handleError(error, rootState.app_variables);
                    });
                return promise;
            }
        },

        deletePageFeedback({commit}) {
            const promise = commit('DELETE_PAGE_FEEDBACK');
            return promise;
        },

        saveExitTest({commit, rootState, dispatch}, payload) {
            const promise = testClient.saveExit(rootState.Auth.token, payload.current_page, payload.user_answers)
                .then((response) => {
                    if (response.status == 200) {
                        responseHandler.setResponseData(commit, response);
                    }
                }).catch((error) => {
                    responseHandler.handleError(error, rootState.app_variables);
                });
            return promise;
        },

        completeTest({commit, rootState, dispatch}, payload) {
            const promise = testClient.complete(rootState.Auth.token, payload.current_page, payload.user_answers)
                .then((response) => {
                    if (response.status == 200) {
                        responseHandler.setResponseData(commit, response);
                    }
                }).catch((error) => {
                    responseHandler.handleError(error, rootState.app_variables);
                });
            return promise;
        },
        getPaymentStatus({commit, rootState, dispatch}) {
            const promise = paymentClient.getPaymentStatus(rootState.Auth.token)
                .then((response) => {
                    if (response.status == 200) {
                        responseHandler.setResponseData(commit, response);
                    }
                }).catch((error) => {
                    responseHandler.handleError(error, rootState.app_variables)
                });
            return promise;
        },
        setSectionAndPage({commit}, {section_index, page_index}) {
            commit('SET_CURRENT_PAGE', {section_index: section_index, page_index: page_index});
        },
        setQuizId({commit}, quiz_id) {
            commit('SET_QUIZ_ID', quiz_id);
        },
        setGroupType({commit}, group_type) {
            commit('SET_GROUP_TYPE', group_type);
        },
        setTheme({commit}, theme) {
            commit('SET_THEME', theme);
        },
        setRemainingTime({commit}, remaining_time) {
            commit('SET_REMAINING_TIME', remaining_time);
        },
        async detectCheating({rootState, commit, dispatch}, {cheat_type, cheat_details}) {
            await testClient.reportCheating(rootState.Auth.token, cheat_type, cheat_details);
            dispatch('Error/setMessage', {
                error: {
                    'error_code': cheat_type,
                    'error_type': 'error',
                    'message': constant.ERROR_MESSAGES[cheat_type]
                }
            }, {root: true});
            dispatch('Error/displayFullPageError', {}, {root: true})

        },

        /**
         * This should only used in initial loading error page.
         * Test settings come with initial data loading which doesn't require additional setting steps.
         * @param commit
         * @param setting
         */
        setTestSettingFromErrorPage({commit}, setting) {
            commit('SET_SETTINGS', setting);
        },
        setQuestionVisibility({ commit }, { questionId, visible }) {
            commit('SET_QUESTION_VISIBILITY', { questionId, visible });
        },
        clearVisibleQuestions({ commit }) {
            commit('CLEAR_VISIBLE_QUESTIONS');
        },
        setBookmarkModalVisibility({ commit }, visible) {
            commit('SET_BOOKMARK_MODAL_VISIBILITY', visible);
        }
    },
    getters: {
        getVisibleQuestions(state, getters, rootState) {
            if (rootState.Test.settings.test.questions_per_page === 1) {
                return [state.data.test.getCurrentQuestionId()];
            } else {
                return state.visible_questions;
            }
        },
        isInProgress: state => state.test_state === constant.STATUS_TEST_IN_PROGRESS,
        isComplete: state => state.test_state === constant.STATUS_TEST_COMPLETE,
        isBookmarkModalOpen: state => state.is_bookmark_modal_visible === true,
    },
    // Modulising stores - spreading stores into different files
    modules: {}
}