<template>
  <div class="browser-instructions">
    <ion-grid class="ion-no-padding">

      <ion-row>
        <ion-col class="image-group">
          <ion-img class="monitoring-browser-full-screen"
                   :src="BASE_URL + '/static/images/monitoring-browser-full-screen.svg'" />
          <ion-img class="monitoring-browser-mouse-outside"
                   :src="BASE_URL + '/static/images/monitoring-browser-mouse-outside.svg'" />
        </ion-col>
      </ion-row>
      <ion-row v-if="settings['proctor_user_monitor_instructions'] !== null && settings['proctor_user_monitor_instructions'] !== ''" class="instructions-row">
        <ion-col>
          <html-content :content="settings['proctor_user_monitor_instructions']"></html-content>
        </ion-col>
      </ion-row>
      <ion-row  v-else class="instructions-row">
        <ion-col>
          <p><html-content :content="language['test_monitoring_browser_line_1']"></html-content></p>
          <p><html-content :content="language['test_monitoring_browser_line_2']"></html-content></p>
          <p><html-content :content="language['test_monitoring_browser_line_3']"></html-content></p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script setup>
import { reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import {IonCol, IonGrid, IonRow, IonImg} from '@ionic/vue';
import HtmlContent from '@/components/html/HtmlContent.vue';

const emit = defineEmits([
  'complete',
]);

const BASE_URL = process.env.BASE_URL;

const store = useStore();
const data = reactive(store.state.Test.data['test_intro']);
const settings = reactive(store.state.Test.settings['test']);
const language = reactive(store.getters.languageSet);

onMounted(() => {
  emit('complete');
});
</script>

<style lang="scss" scoped>
@import '@/theme/variables';

.browser-instructions {

  .instructions-row {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .image-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .monitoring-browser-full-screen {
      display: inline-flex;
      width: 98px;
      height: 69px;
      margin: 0 $between-space 0 0;
    }

    .monitoring-browser-mouse-outside {
      display: inline-block;
      width: 110px;
      height: 79px;
      margin: 0 $between-space 0 0;
    }
  }
}
</style>