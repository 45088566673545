import TestSection from "./testSection";

export default class TestData {
    constructor(data, test_settings) {
        if (data.test.score_id) {
            this.score_id = data.test.score_id;
        }
        this.current_page = data.test.current_page;
        this.current_section = null;
        this.sections = [];
        this.total_page = data.test.total_pages;
        this.load_timestamp = data.test.load_timestamp;
        this.__setSections(data.test.sections, test_settings);
        this.page_feedback = null;
        this.num_of_questions = data.test.num_of_questions;
        this.offline_soon_message = data.test.offline_soon_message;
        this.last_page_visited = data.test.last_page_visited;
        this.survey_test = test_settings.survey_test;
    }


    getCurrentPage() {
        let section = this.sections[this.current_section];
        return section.getPage(this.current_page);
    }

    getCurrentQuestionId() {
        const page = this.getCurrentPage();
        return (page && page.contents.length) ? page.contents[0].id : 0;
    }

    getCurrentPageUserAnswer() {
        let section = this.sections[this.current_section];
        return section.getPageUserAnswer(this.current_page);
    }

    updateData(newData, test_settings) {

        this.current_page = newData.current_page;
        if (newData.sections !== null || newData.sections !== []) {
            this.__setSections(newData.sections, test_settings);
        }
        this.offline_soon_message = newData.offline_soon_message;
    }

    setPageFeedback(data) {
        if (data.test != null && data.test.hasOwnProperty('page_feedback')
            && data.test.page_feedback !== null
            && data.test.page_feedback.hasOwnProperty('question_feedbacks')
        ) {
            this.page_feedback = data.test.page_feedback.question_feedbacks;
        }
    }

    getNumberOfQuestionInTest() {
        return this.num_of_questions;
    }

    getNumberOfQuestionInSection() {
        let section = this.sections[this.current_section];
        return section.number_of_question;
    }

    getPageFeedback() {
        if (this.page_feedback != null && this.page_feedback != {}) {
            return this.page_feedback;
        } else {
            return null;
        }
    }

    deletePageFeedback() {
        this.page_feedback = null;
    }

    /**
     * Set sections for test
     * @param sections
     * @private
     */
    __setSections(sections, test_settings) {

        sections.forEach((section, index) => {
            let test_section = new TestSection(section, test_settings);

            if (test_section.first_page_index <= this.current_page && this.current_page <= test_section.last_page_index) {
                this.current_section = index;
            }
            if (this.sections.hasOwnProperty(index)) {
                this.sections[index] = test_section;
            } else {
                this.sections.push(test_section);
            }
        });
    }
}