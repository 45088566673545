<template>
  <ion-page>
    <ion-content :fullscreen="true" scrollX="true" scrollY="true">
      <Header></Header>

      <div class="template">
        <test-title :display-price="false"></test-title>
        <ion-card>
          <GlobalErrorModal v-if="buttonAction" :button-action="buttonAction" :button-message="buttonMessage"
                            :quiz-id="quiz_id"></GlobalErrorModal>
          <GlobalErrorModal v-else :hide-button="true"></GlobalErrorModal>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonCard, IonCardContent, IonContent, IonPage} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import store from '@/store';
import GlobalErrorModal from "../components/html/GlobalErrorModal";
import authHandler from "@/router/authHandler";
import constant from "../constant";
import Header from "../components/html/Header";
import TestTitle from "../components/html/TestTitle";

export default defineComponent({
  name: 'ErrorPage',
  components: {
    GlobalErrorModal,
    IonCardContent,
    IonCard,
    IonPage,
    IonContent,
    Header,
    TestTitle,
  },
  beforeMount() {
  },
  mounted() {
    /**
     * Remove before unload event. The APP.vue file is loaded later than this page,
     * unloadEvent setup should be delayed until App.vue file is loaded
     */
    setTimeout(() => {
      window.removeEventListener('beforeunload', this.$popupUnLoadEvent);
    }, 1000);
  },
  setup(props, ctx) {
    let quiz_id = new URL(location.href).searchParams.get('quiz') ?? '';
    const iframe_mode = store.state.iframe_mode;
    const state = reactive({});

    //set the icon for the error page
    store.state.Error.error.type = 'error';
    
    // reset auth-token if required
    __resetAuthToken();

    /*** button action is only applicable in the CM website, not in iFrame mode
     * When login redirect happening inside of iframe and rotate auth token,
     * It is no longer valid in the 3party website. So, the app shouldn't be refreshed inside of iframe.
     */
    const buttonAction = computed(() => {
      let button_action = null;

      if (!iframe_mode && [constant.ERROR_MULTIPLE_BROWSER_DETECTED,
        constant.ERROR_AUTH_TOKEN_EXPIRED,
        constant.ERROR_ACCESS_TOKEN_EXPIRED].includes(store.state.Error.error.error_code)) {
        button_action = 'login_redirect'
      }

      let group_type = store.getters.groupType;
      if (group_type == constant.GROUP_TYPE_GROUP && [constant.ERROR_GENERIC_SERVER_ERROR, 500].includes(store.state.Error.error.error_code)) {
        button_action = 'login_redirect'
      }

      return button_action;
    });

    /**
     * Reset Cookie if required.
     * @private
     */
    function __resetAuthToken() {
      let error_arrays = [
        constant.ERROR_AUTH_TOKEN_EXPIRED, constant.ERROR_NOT_UNIQUE_CM_USER_ID,
        constant.ERROR_TEST_LIMIT_REACHED, constant.ERROR_INVALID_QUIZ_ID,
        constant.ERROR_TEST_UNAVAILABLE, constant.ERROR_IP_NOT_ALLOWED,
        constant.ERROR_INACTIVE_ACCOUNT, constant.ERROR_TOO_MANY_ATTEMPTS,
        constant.ERROR_NOT_UNIQUE_CM_USER_ID, constant.ERROR_NO_CM_USER_ID,
        constant.ERROR_INVALID_RESUME_LINK, constant.ERROR_NO_CREDIT_PAID_USER,
        constant.ERROR_NO_CREDIT_FREE_USER, constant.ERROR_COUNTRY_NOT_ALLOWED,
        constant.ERROR_GENERIC_SERVER_ERROR, constant.ERROR_NO_QUESTIONS_IN_TEST,
        constant.ERROR_TEST_NO_LONGER_AVAILABLE
      ];

      if (error_arrays.includes((store.state.Error.error.error_code))) {
        authHandler.deleteToken(quiz_id);
      }
    }

    const buttonMessage = computed(() => {
      let language_set = store.getters.languageSet;
      return language_set['ex_link_start_test'] ?? 'Start';
    });

    return {state, quiz_id, buttonAction, buttonMessage};
  },
});
</script>

<style scoped lang="scss">
.template {
  padding-top: 1px;
}

.icon-flag {
  width: 53px;
  height: 68.96px;
  background-size: 53px;
  position: relative;
  display: block;
  margin: auto;
  margin-top: 93px;
}

ion-card-header {
  color: $text-color-primary;
  font-size: 24px;
  //styleName: H1;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 28px;
}

ion-card-content {
  //styleName: Body / Regular;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  width: 62%;
  display: block;
  margin: auto;
  margin-bottom: 74px;

}
</style>