import { ref } from 'vue';
import { useEventListener } from '@vueuse/core';

/**
 * Reactively track when mouse leaves the document.
 */
export function useMouseOut() {

  const isOutside = ref(true);

  useEventListener(document, 'mouseover', (event) => {
    isOutside.value = false;
  });

  useEventListener(document, 'mouseout', (event) => {
    if (
      event.clientY <= 0 ||
      event.clientX <= 0 ||
      (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)
    ) {
      isOutside.value = true;
    }
  });

  return isOutside;
}
