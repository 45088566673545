<template>
  <ion-modal
      :is-open="showModal"
      :backdrop-dismiss="false"
      :swipe-to-close="false"
      :css-class="['auto-height blurred']">
    <div class="monitoring-camera-modal ion-no-padding">
      <ion-row>
        <ion-col>
          <Banner
              :display-icon="true"
              :message="language['test_monitoring_camera_disconnect_message']"
              banner-type="warning"
              style="margin-bottom: 15px;"/>
        </ion-col>
      </ion-row>
      <ion-row class="drop-shadow-row">
        <ion-col size="12">
          <CameraPhoto
              :capture="false"
              :stream="cameraStream" />
        </ion-col>
        <ion-col size="12">
          <CameraDevice
              :id="cameraId"
              :label="cameraLabel"
              :cameras="cameras" @change="onCameraChange" />
        </ion-col>
      </ion-row>
      <ion-row class="modal-actions">
        <ion-button
            class="modal-close-button"
            :disabled="!isCameraStreaming"
            @click="closeModal">
          {{ buttonLabel }}
        </ion-button>
      </ion-row>
    </div>
  </ion-modal>
  <CameraFeed
      v-if="!showModal"
      :stream="cameraStream"
      @complete="onComplete" />
</template>

<script setup>
import { ref, computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import { IonButton, IonCol, IonModal, IonRow } from '@ionic/vue';
import { useMonitorCamera } from '@/core/useMonitor/camera';
import CameraDevice from '@/components/test/CameraDevice.vue';
import CameraPhoto from '@/components/test/CameraPhoto.vue';
import CameraFeed from '@/components/test/CameraFeed.vue';
import Banner from '@/components/html/Banner.vue';



const store = useStore();
const language = reactive(store.getters.languageSet);
const complete = computed(() => store.getters['Test/isComplete']);
const buttonLabel = computed(() => language['link_continue']);
const confirmed = ref(true);
const problem = ref(false);
const showModal = ref(false);
const closeModal = () => {
  showModal.value = false;
  confirmed.value = true;
  problem.value = false;
  store.dispatch('Monitoring/unHaltTest');
};

const {
  isCameraSupported,
  isCameraStreaming,
  isCameraPermissionGranted,
  cameras,
  cameraId,
  cameraLabel,
  cameraEnabled,
  cameraStream,
  cameraEvents,
  cameraConstraints,
  cameraAutoSwitch,
  startCamera,
  stopCamera,
  restartCamera,
} = useMonitorCamera({
  onConnect: function() {
    store.dispatch('Monitoring/setCameraConnected');
  },
  onDisconnect: function() {
    store.dispatch('Monitoring/setCameraDisconnected');
  },
  onError: function(error) {
    confirmed.value = false;
    problem.value = true;
  }
});

const onComplete = () => {
  cameraId.value = null;
  cameraAutoSwitch.value = false;
  stopCamera();
  store.dispatch('Monitoring/setComplete');
};

const onCameraChange = (id) => {
  cameraId.value = id;
};

watch(cameraId, () => {
  store.dispatch('Monitoring/setCameraId', cameraId.value);
});

watch(isCameraStreaming, () => {
  if (showModal.value || problem.value) {
    return;
  }
  if (!isCameraStreaming.value || (isCameraStreaming.value && !confirmed.value)) {
    showModalWithDelay(true);
  } else {
    showModalWithDelay(false);
  }
});

watch(problem, () => {
  console.log('watch error', problem.value);
  if (showModal.value || isCameraStreaming.value) {
    return;
  }
  showModalWithDelay(problem.value);
}, { immediate: true });

let showModalTimeout = null;

const showModalWithDelay = (value) => {
  if (showModalTimeout) {
    clearTimeout(showModalTimeout);
  }

  if (value) {
    showModalTimeout = setTimeout(() => {
      confirmed.value = false;
      showModal.value = true;
    }, 500);
  } else {
    confirmed.value = true;
    showModal.value = false;
  }
};

</script>

<style lang="scss" scoped>
ion-modal.blurred {
  backdrop-filter: blur(10px);
}

ion-modal.auto-height {
  --height: auto;
}

ion-modal.auto-height .ion-page {
  position: relative;
  display: block;
  contain: content;
}

ion-modal.auto-height .ion-page .inner-content {
  max-height: 80vh;
  overflow: auto;
}

.monitoring-camera-modal {
  /*background-color: $color-yellow-bg !important;*/
  padding: 15px;
}

.drop-shadow-row {
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  border: 1px solid #E9ECEF;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
}

.modal-content {
  padding-left: 15px;
  padding-right: 15px;
}

.modal-icon .icon {
  border: 0;
  width: 45px;
  height: 45px;
  margin: 48.5px auto auto;
  background-size: 45px;
}

.modal-message {
  min-width: fit-content;
  max-width: 399px;
  margin: 36px auto;
  font-weight: bold;
}

.modal-actions {
  background-color: $color-white;
  height: 84px;
  padding-top: 20px;
  padding-bottom: 16px;
}

.modal-close-button {
  margin: auto;
  --background: $color-white;
  border: 1px solid #CCD3DD;
  border-radius: 4px;
  --color: #282B30;
  --background-hover: #454F59;
}

</style>