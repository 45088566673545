<template>
  <div :class="['camera-feed', props.stream === undefined ? 'disconnected' : 'connected']">
    <div class="camera-background">
      <video
          ref="video"
          muted
          autoplay />
      <canvas ref="canvas" style="display: none;" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, watchEffect, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useIntervalFn } from '@vueuse/core';
import { useMonitorSnapshot } from '@/core/useMonitor/snapshot';

const emit = defineEmits([
  'complete',
]);

const props = defineProps({
  // MediaStream instance containing video tracks
  stream: {
    type: Object,
    default: undefined
  },
});

const store = useStore();
const connected = computed(() => store.getters['Monitoring/isCameraStreaming']);
const questions = computed(() => store.getters['Test/getVisibleQuestions']);
const complete = computed(() => store.getters['Test/isComplete']);
const video = ref(null);
const canvas = ref(null);
const queue = ref([]);
const processing = ref(false);

const {
  capture: captureImage,
  submitImage: submitImage,
  submitSnapshot: submitSnapshot,
} = useMonitorSnapshot(video, canvas);

onMounted(() => {
  const snapshot = store.getters['Monitoring/getSnapshotByType']('resume');
  if (snapshot && !snapshot.captured) {
    queue.value.push(snapshot);
  }
});

watch(questions, (visibleQuestions) => {
  const notCaptured = store.getters['Monitoring/getSnapshotsNotCaptured'];
  notCaptured.forEach((snapshot) => {
    if (snapshot.question_id) {
      const visible = visibleQuestions.includes(snapshot.question_id);
      const queued = queue.value.find((item) => item.id === snapshot.id) !== undefined;
      if (visible && !queued) {
        queue.value.push(snapshot);
      }
    }
  });
}, { deep: true });

const processQueue = () => {
  if (processing.value || !video.value.srcObject || !queue.value.length) {
    return;
  }

  processing.value = true;

  queue.value.forEach((item, index, list) => {
    const snapshot = store.getters['Monitoring/getSnapshotById'](item.id);
    if (! snapshot || snapshot.captured) {
      list.splice(index, 1);
    } else {
      captureImage().then((image) => {
        if (image) {
          submitSnapshot(snapshot, image).then((success) => {
            if (success) {
              store.dispatch('Monitoring/setSnapshotCapturedById', snapshot.id);
              list.splice(index, 1);
            }
          });
        }
      });
    }
  });

  processing.value = false;
};

useIntervalFn(() => {
  processQueue();
}, 3000);

watch(complete, (value) => {
  if (value) {
    captureImage().then((image) => {
      if (image) {
        submitImage('complete', image).then(() => {
          emit('complete');
        });
      }
    });
  }
});

watchEffect(() => {
  if (video.value) {
    video.value.srcObject = props.stream;
  }
});

</script>

<style lang="scss" scoped>
.camera-feed {
  border-radius: 8px;
  position: fixed;
  overflow: hidden;
  left: 10px;
  bottom: 10px;
  width: 120px;
  height: 120px;
}

.camera-feed video {
  width: 100%;
  height: auto;
  display: block;
}

.camera-feed.connected {
}

.camera-feed.disconnected {
}

.camera-background {
  background-color: #212121;
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 100%;
}

.camera-feed.disconnected .camera-background:after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: transparent url('~@/assets/image/noise.png') repeat 0 0;
  animation: noise-animation .2s infinite;
  opacity: 1;
}

@keyframes noise-animation {
  0% { transform: translate(0,0) }
  10% { transform: translate(-5%,-5%) }
  20% { transform: translate(-10%,5%) }
  30% { transform: translate(5%,-10%) }
  40% { transform: translate(-5%,15%) }
  50% { transform: translate(-10%,5%) }
  60% { transform: translate(15%,0) }
  70% { transform: translate(0,10%) }
  80% { transform: translate(-15%,0) }
  90% { transform: translate(10%,5%) }
  100% { transform: translate(5%,0) }
}

</style>