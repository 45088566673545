<template>
  <div class="custom-instructions">
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col class="content">
          <div>
            <html-content :content="data['custom_instruction']" :allow-magnifying-image="true"/>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { IonCol, IonGrid, IonRow } from '@ionic/vue';
import HtmlContent from '@/components/html/HtmlContent.vue';

const emit = defineEmits([
  'complete',
]);

const store = useStore();
const data = reactive(store.state.Test.data['test_intro']);

onMounted(() => {
  emit('complete');
});
</script>

<style lang="scss" scoped>
@import '@/theme/variables';
.custom-instructions {

  .content {
    overflow-x: auto;
    background-color: $color-white;
    border-radius: $default-border-radius;
    margin: 0 0 32px;
  }
}
</style>