/***
 Custom tooltip directive
 - Show custom tooltip.
 usage ->  <div v-tooltip="{location: 'top|bottom'}" data-title="title here" data-display-mobile="true"> ... </div>

 data-title: show the tooltip text
 data-display-mobile: when set true: it show tooltip on the mobile device and do the button action on second touch
 ***/

export default {

    beforeMount(el, binding) {

    },

    mounted(el, binding) {

        // handling mobile device touch event
        el.addEventListener('touchstart', (event) => {
            if(el.dataset.displayMobile == 'true') {

                if (el.dataset.tooltipOpened != 'true') {

                    event.preventDefault();
                    el.dataset.tooltipOpened = true
                    displayTooltip(el);

                    setTimeout(() => {
                        el.dataset.tooltipOpened = false;
                        hideTooltip();
                    }, 3000);

                } else {
                    hideTooltip();
                    el.dataset.tooltipOpened = false;
                    return true;
                }
            }
            else{
                el.dataset.tooltipOpened = false;
            }
        })

        el.addEventListener('mouseover', () => {
            displayTooltip(el);
        });


        el.addEventListener('mouseout', () => {
            hideTooltip();
        })

        
        function hideTooltip() {
            let tooltipList = document.querySelectorAll('.tooltip');
            tooltipList.forEach((tooltip) => {
                document.body.removeChild(tooltip);
            });
        }


        function displayTooltip(el) {

            // When touch event happening, tooltipOpened value set to
            //  - true, before display tooltip
            //  - false, before hiding tooltip. So when tooltip Opened ='false' we shouldn't show the tooltip again.
            if (el.dataset.tooltipOpened == 'false') {
                return;
            }


            let tooltipDom = document.createElement('div');
            tooltipDom.innerHTML = el.dataset.title;
            tooltipDom.classList.add('tooltip');
            var location = el.getBoundingClientRect();
            tooltipDom.style.position = 'absolute';

            // center Y position of the element =  (top of element + middle Y of element) = location.top + el.offsetHeight / 2
            let elemCenterY = location.top + el.offsetHeight / 2;
            switch (binding.value.location) {
                case "top":
                    tooltipDom.style.top = (elemCenterY - 60) + 'px';
                    break;
                case "bottom":
                    tooltipDom.style.top = (elemCenterY + 35) + 'px';
                    break;
            }


            document.body.appendChild(tooltipDom);
            // center  X position of the element =  (start of element + middle of element) = location.left + el.offsetWidth / 2
            let elemCenterX = location.left + el.offsetWidth / 2;
            tooltipDom.style.left = (elemCenterX - tooltipDom.offsetWidth / 2) + 'px';
        }
    },

}

