<template>
  <ion-modal
      :is-open="showModal"
      :backdrop-dismiss="false"
      :swipe-to-close="false"
      :css-class="['auto-height blurred']">
    <div class="monitoring-error-modal ion-no-padding">
      <ion-row class="ion-no-padding">
        <ion-col class="modal-content">
          <ion-row class="modal-icon">
            <div class="icon warning--unfilled" />
          </ion-row>
          <ion-row class="modal-message">
            <html-content :content="message" text-align="center" />
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="modal-actions">
        <ion-button class="modal-close-button" @click="closeModal">
          {{ buttonLabel }}
        </ion-button>
      </ion-row>
    </div>
  </ion-modal>
</template>

<script setup>
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import { IonButton, IonCol, IonModal, IonRow } from '@ionic/vue';
import HtmlContent from '@/components/html/HtmlContent.vue';

const store = useStore();
const language = reactive(store.getters.languageSet);
const error = computed(() => store.state.Monitoring.error);
const message = computed(() => language['test_error_alert_detected_you_left_test']);
const showModal = computed(() => store.getters['Monitoring/isTestHalted']);
const buttonLabel = computed(() => language['link_continue']);
const closeModal = () => store.dispatch('Monitoring/unHaltTest', null, { root: true });

</script>

<style lang="scss" scoped>
ion-modal.blurred {
  backdrop-filter: blur(10px);
}

ion-modal.auto-height {
  --height: auto;
}

ion-modal.auto-height .ion-page {
  position: relative;
  display: block;
  contain: content;
}

ion-modal.auto-height .ion-page .inner-content {
  max-height: 80vh;
  overflow: auto;
}

.modal-content {
  background-color: $color-yellow-bg !important;
}

.modal-icon .icon {
  border: 0;
  width: 45px;
  height: 45px;
  margin: 48.5px auto auto;
  background-size: 45px;
}

.modal-message {
  min-width: fit-content;
  max-width: 399px;
  margin: 36px auto;
  font-weight: bold;
}

.modal-actions {
  background-color: $color-white;
  height: 84px;
  padding-top: 20px;
  padding-bottom: 16px;
}

.modal-close-button {
  margin: auto;
  --background: $color-white;
  border: 1px solid #CCD3DD;
  border-radius: 4px;
  --color: #282B30;
  --background-hover: #454F59;
}

</style>