<template>
  <div class="camera-device">
    <ion-select
        v-model:value="props.id"
        class="camera-select"
        aria-label="Camera Device"
        placeholder="Select Camera Device"
        label="Select Camera Device"
        label-placement="floating"
        fill="outline"
        :disabled="props.cameras.length === 0"
        :selected-text="props.label"
        @ionChange="onCameraChange">
      <ion-select-option
          v-for="camera in props.cameras"
          :value="camera.deviceId">
        {{ camera.label }}
      </ion-select-option>
    </ion-select>
  </div>
</template>

<script setup>
import { IonSelect, IonSelectOption } from '@ionic/vue';
import { useStore } from 'vuex';

const emit = defineEmits([
  'change',
]);

const props = defineProps({
  // Default camera device ID
  id: {
    type: String,
    default: null
  },
  // Default camera device ID
  label: {
    type: String,
    default: null
  },
  // List of available camera devices
  cameras: {
    type: Array,
    default: []
  },
});

const store = useStore();

const onCameraChange = (event) => {
  emit('change', event.detail.value);
}

</script>

<style lang="scss" scoped>
.camera-device {
  display: flex;
  justify-content: center;
}

</style>